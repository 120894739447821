/* #####DE 601 ATÉ 768 PX##### */

@media screen and (min-width: 601px) and (max-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0;
  }

  /* HEADER */

  .AcxHeader > .conteudo > .identidadeVisual {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img01 {
    width: 100%;
  }

  /* .AcxHeader > .conteudo > .identidadeVisual > .img03 {
    width: 50%;
    margin-right: 25px;
  } */

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }

  /* BCXSECTIONTURISMO */

  .BcxSectionTurismo > article {
    width: 85%;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    width: 540px;
  }

  .BcxSectionD > .descricao > .title {
    flex-direction: row;
    gap: 25px;
  }

  .BcxSectionD > .descricao > .title > img {
    max-width: 250px;
    align-self: center;
  }

  /* BCXSECTIONE */

  .BcxSectionE > .rowCard .texto > h3,
  .BcxSectionE > .rowCard .texto > h3 > span {
    font-size: 43px;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }
}

/* #####DE 769 ATÉ 992 PX##### */

@media screen and (min-width: 769px) and (max-width: 922px) {
  .container {
    max-width: 922px;
  }

  /* HEADER */

  .AcxHeader > .conteudo > .identidadeVisual {
    flex-direction: row;
    justify-content: right;
    align-items: center;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img01 {
    width: 52%;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img03 {
    width: 50%;
    margin-right: 25px;
  }

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }

  .BcxSectionA > .logos > .logocred {
    width: 60%;
  }
  .BcxSectionA > .logos > .parceiros {
    width: 90%;
  }

  /* BCXSECTIONTURISMO */
  .BcxSectionTurismo > article {
    width: 85%;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    width: 720px;
  }

  .BcxSectionD > .descricao > .title {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }

  .BcxSectionD > .descricao > .title > img {
    max-width: 250px;
    align-self: center;
  }

  /* BCXSECTIONE */

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo {
    padding: 0 100px;
  }
  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    width: 85%;
  }
}

/* #####DE 923 ATÉ 1200 PX##### */

@media screen and (min-width: 923px) and (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  /* HEADER */

  .AcxHeader > .conteudo > .identidadeVisual {
    height: 100vh;
    flex-direction: row;
    align-items: center;
    position: absolute;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img01 {
    width: 55%;
    overflow: hidden;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img03 {
    width: 55%;
    margin-right: 25px;
  }

  .AcxHeader > .conteudo > .img02 {
    width: min-content;
    max-width: 30%;
    align-self: flex-end;
  }

  .AcxHeader > .conteudo > .img04 {
    width: min-content;
    max-width: 50%;
    align-self: flex-end;
    margin-bottom: 10px;
  }

  /* BCXSECTIONA */

  .BcxSectionA > .text {
    width: 85%;
  }
  .BcxSectionA > .logos > .logocred {
    width: 50%;
  }
  .BcxSectionA > .logos > .parceiros {
    width: 75%;
  }

  /* BCXSECTIONTURISMO */
  .BcxSectionTurismo > article {
    width: 85%;
  }
  /* BCXSECTIONB */

  .BcxSectionB > .cards {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 0px;
  }

  /* BCXSECTIONC */

  .BcxSectionC > .cards {
    display: grid;
    grid-template-areas: "a a" "b b";
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    max-width: 960px;
  }

  .BcxSectionD > .descricao > .title {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }

  .BcxSectionD > .descricao > .title > img {
    max-width: 250px;
    width: 250px;
    align-self: center;
  }

  .BcxSectionD > .descricao > p {
    margin-top: 10px;
    text-align: center;
  }

  .BcxSectionD > .botoes {
    flex-direction: row;
    gap: 50px;
  }

  .BcxSectionD > .botoes > .content {
    width: 50%;
  }

  /* BCXSECTIONE */

  .BcxSectionE {
    /* width: 98%; */
    justify-content: center;
  }

  .BcxSectionE > .rowCard {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 25px;
  }

  .BcxSectionE > .micEmp {
    text-align: right;
  }

  .BcxSectionE > .rowCard > .imagem > img {
    min-width: 400px;
  }

  .BcxSectionE > .rowCard > .texto {
    max-width: 450px;
  }

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .micInd,
  .BcxSectionE > .peqEmp {
    flex-direction: row-reverse;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
    border-radius: 50px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  .BcxSectionF > .conteudo {
    padding: 0 125px;
  }
  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    width: 85%;
  }
}

/* #####ACIMA DE 1200 PX##### */

@media screen and (min-width: 1201px) {
  .container {
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }

  /* HEADER */

  .AcxHeader {
    width: 100vw;
  }

  .AcxHeader > .conteudo > .identidadeVisual {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    flex-direction: row;
    justify-content: right;
    align-items: center;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img01 {
    width: 51%;
  }

  .AcxHeader > .conteudo > .identidadeVisual > .img03 {
    width: 50%;
    margin-right: 50px;
  }

  .AcxHeader > .conteudo > .img02 {
    width: 25%;
  }

  .AcxHeader > .conteudo > .img04 {
    width: 20%;
    margin-right: 25px;
  }

  /* BCXSECTIONA */

  .BcxSectionA {
    max-width: 1320px;
    flex-direction: row;
    gap: 150px;
    padding: 0 100px;
  }

  .BcxSectionA > .text {
    width: 50%;
  }

  .BcxSectionA > .logos {
    width: 50%;
  }

  .BcxSectionA > .logos > .logocred {
    width: 75%;
  }
  .BcxSectionA > .logos > .parceiros {
    margin-top: 15px;
    width: 100%;
  }

  /* BCXSECTIONTURISMO */

  .BcxSectionTurismo {
    max-width: 1320px;
  }

  .BcxSectionTurismo > article {
    flex-direction: row;
  }

  .BcxSectionTurismo > article > .imgs > img {
    max-width: 85%;
  }

  /* BCXSECTIONB */

  .BcxSectionB {
    width: 100%;
    max-width: 1320px;
  }
  .BcxSectionB > .cards {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }

  /* BCXSECTIONC */

  .BcxSectionC {
    width: 100%;
    max-width: 1320px;
  }
  .BcxSectionC > .cards {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0px;
  }

  /* BCXSECTIOND */

  .BcxSectionD {
    max-width: 1320px;
  }

  .BcxSectionD > .descricao > .title {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }

  .BcxSectionD > .descricao > .title > img {
    max-width: 250px;
    width: 250px;
    align-self: center;
  }

  .BcxSectionD > .descricao > p {
    margin-top: 10px;
    text-align: center;
  }

  .BcxSectionD > .botoes {
    flex-direction: row;
    gap: 50px;
  }

  .BcxSectionD > .botoes > .content {
    width: 50%;
  }

  /* BCXSECTIONE */

  .BcxSectionE {
    max-width: 1320px;
    justify-content: center;
  }

  .BcxSectionE > .rowCard {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 50px;
  }

  .BcxSectionE > .micEmp {
    text-align: right;
  }

  .BcxSectionE > .rowCard > .imagem > img {
    min-width: 400px;
  }

  .BcxSectionE > .rowCard > .texto {
    max-width: 500px;
  }

  .BcxSectionE > .rowCard > .texto > h3,
  .BcxSectionE > .rowCard > .texto > h3 > span {
    font-size: 43px;
  }

  .BcxSectionE > .micInd,
  .BcxSectionE > .peqEmp {
    flex-direction: row-reverse;
  }

  .BcxSectionE > .aviso {
    flex-direction: row;
    gap: 50px;
    padding: 30px 100px;
    border-radius: 50px;
  }

  .BcxSectionE > .aviso > .imgAviso {
    padding-bottom: 0;
  }

  /* BCXSECTIONF */

  #sectionF {
    background: url(../imgs/bg-noise.png), url(../imgs/bg-7.png),
      url(../imgs/bg-6.jpg), #83b842;
    background-repeat: repeat, no-repeat, no-repeat;
    background-position: center, right 20px, center;
    background-attachment: fixed;
    background-size: auto, contain, contain;
  }

  .BcxSectionF {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
  }

  .BcxSectionF > .conteudo {
    width: 50%;
  }

  .BcxSectionF > .conteudo > .title > h2,
  .BcxSectionF > .conteudo > .title > h2 > span {
    font-size: 43px;
    text-align: left;
  }

  .BcxSectionF > .conteudo > .title {
    margin-left: 25px;
  }

  .BcxSectionF > .conteudo > .texto > .inscMunic {
    width: 75%;
  }

  /* BCXSECTIONG */

  .BcxSectionG {
    max-width: 1320px;
  }
}
