@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
}

body {
  max-width: 100%;
  min-height: 100vh;
}

.container {
  max-width: 600px;}

/* HEADER */

.AcxHeader > .conteudo > .identidadeVisual {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.AcxHeader > .conteudo > .identidadeVisual > .img01 {
  width: 100%;
  }

.AcxHeader>.conteudo .img01 {
  display: block; 
  width: 100%;
  height: auto;
}

.AcxHeader>.conteudo>.img04 {
  width: 40%;
  align-self: flex-end;
  margin-bottom: 10px;
}

/* BCXSECTIONA */

#SectionA{
  width: 100%;
  background-color: #cacaca;
}

.BcxSectionA {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: max-content;
}

.BcxSectionA>.text {
  align-self: center;
  width: 90%;
  text-align: justify;
  margin-bottom: 50px;
}

.BcxSectionA>.text>p {
  padding: 10px 0;
}

.BcxSectionA>.logos {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.BcxSectionA>.logos img{
  display: block;
  width: 100%;
  max-width: 485px;
  height: auto;
}

.BcxSectionA>.logos>.logocred {
  width: 75%;
}

.BcxSectionA>.logos>.parceiros {
  margin-top: 15px;
  width: 100%;
}

/* BCXSECTIONTURISMO */

#SectionTurismo {
  background: url(../imgs/bg-noise.png) repeat #fbf7ec;
  background-color: #fbf7ec;
}

.BcxSectionTurismo {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BcxSectionTurismo>.title {
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
}

.BcxSectionTurismo>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionTurismo>article {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.BcxSectionTurismo>article>.imgs {
  display: block;
  height: auto;
  max-width: 100%;
  object-fit: contain;

}

.BcxSectionTurismo>article>.imgs>img {
  max-width: 100%;
}

.BcxSectionTurismo>article>.text {
  text-align: justify;
}

.BcxSectionTurismo>article>.text>h3 {
  padding: 0 0 25px 0;
}

.BcxSectionTurismo>article>.text>p {
  padding: 5px 0;
}

/* BCXSECTIONB */

#sectionB {
  background: url(../imgs/bg-noise.png) repeat #f9b11c;
}

.BcxSectionB {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BcxSectionB>.title {
  color: #ffffff;
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
}

.BcxSectionB>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionB>.cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.BcxSectionB>.cards>.card {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}

.BcxSectionB>.cards>.card>h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 400;
}

/* BCXSECTIONC */

#sectionC {
  background: url(../imgs/bg-noise.png) repeat #83b841;
}

.BcxSectionC {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BcxSectionC>.title {
  color: #ffffff;
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 100px;
}

.BcxSectionC>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionC>.cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: top;
}

.BcxSectionC>.cards>.card {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}

.BcxSectionC>.cards>.card>h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 400;
}

.BcxSectionC>.rodape>p {
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
}

/* BCXSECTIOND */

#sectionD {
  /* background: url(../imgs/bg-3.jpg) center no-repeat #244b9a; */
  /* background-size: auto; */
  background-color: #244b9a;
}

#sectionD>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionD {
  width: 90%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
}

.BcxSectionD>.descricao {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.BcxSectionD>.descricao>.title {
  display: flex;
  flex-direction: column;
}

.BcxSectionD>.descricao>.title>img {
  max-width: 194px;
}

.BcxSectionD>.descricao>.title>h2 {
  font-size: 43px;
  font-weight: 400;
  margin: 35px 0;
  text-align: center;
}

.BcxSectionD>.descricao>.title>h2>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionD>.descricao>p {
  margin-top: 15px;
}

.BcxSectionD>.botoes {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.BcxSectionD>.botoes>.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  text-align: center;
}

.BcxSectionD>.botoes>.content>h3 {
  font-size: 20px;
  padding: 15px;
}

.BcxSectionD>.botoes>.content>a.btn {
  width: 100%;
  background-color: #212e3a;
  padding: 20px 15px;
  margin: 0 0 30px 0;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  color: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.BcxSectionD>.botoes>.content>a.btn:hover {
  color: #00a95a;
  background-color: #ffffff;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

/* BCXSECTIONE */

#sectionE {
  background: url(../imgs/bg-noise.png) repeat #ABA7AD;
}

#sectionE>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionE {
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  color: #ffffff;
}

.BcxSectionE>.title {
  font-size: 43px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 50px;
}

.BcxSectionE>.title>span {
  font-size: 43px;
  font-weight: 600;
}

.BcxSectionE>.rowCard {
  width: 90%;
  text-align: center;
}

.BcxSectionE>.rowCard>.imagem>img {
  max-width: 100%;
}

.BcxSectionE>.rowCard>.texto>h3 {
  font-size: 33px;
  /* text-align: center; */
  color: #ffc30d;
  margin-bottom: 30px;
}

.BcxSectionE>.rowCard>.texto>h3>span {
  font-size: 33px;
  font-weight: 400;
}

.BcxSectionE>.rowCard>.texto>p {
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: 300;
}

.BcxSectionE>.rowCard>.texto>p>span {
  color: #ffc30d;
  font-size: 25px;
  font-weight: 600;
}
.BcxSectionE>.rowCard>.texto> p.cresolYellow{
  color: #ffc30d;
  font-weight: 500;
}
.BcxSectionE>.rowCard>.texto>p .sicrediGreen {
  color: #5d852e;
  font-weight: 500;
}

.BcxSectionE>.rowCard>.texto>p>.fungetur {
  color: #ffffff;
  font-weight: 600;
}

.BcxSectionE>.rowCard>.texto>p>.parcelas {
  color: #ffffff;
  font-weight: 500;
}

.BcxSectionE>.aviso {
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffc30d;
  border-radius: 30px;
  line-height: 25px;
}

.BcxSectionE>.aviso>.imgAviso {
  padding-bottom: 20px;
}

.BcxSectionE>.aviso>.textoAviso {
  color: #7b4a98;
}

/* BCXSECTIONF */

#sectionF {
  background: url(../imgs/bg-noise.png), url(../imgs/bg-6.jpg), #83b842;
  background-repeat: repeat, no-repeat;
  background-position: center, center;
  background-attachment: fixed;
  background-size: auto, auto;
}

.BcxSectionF {
  color: #ffffff;
  padding: 100px 0px;
}

.BcxSectionF>.conteudo>.title>h2,
.BcxSectionF>.conteudo>.title>h2>span {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: #ffc30d;
}

.BcxSectionF>.conteudo>.title>h2>span {
  font-weight: 600;
}

.BcxSectionF span {
  font-weight: 600;
}

.BcxSectionF>.conteudo>.texto {
  margin-top: 50px;
  padding: 0px 25px;
}

.BcxSectionF>.conteudo>.texto>p {
  margin-bottom: 30px;
  line-height: 25px;
}

.BcxSectionF>.conteudo>.texto>p>ul>li {
  list-style-type: none;
  margin-bottom: 15px;
}

/* accordion MENU SectioF */

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab {
  width: 100%;
  color: #ffffff;
  overflow: hidden;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #ffc30d;
  font-weight: bold;
  cursor: pointer;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-label:hover {
  background: darken(#2c3e50, 10%);
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  border: solid 2px #f9b11c;
  background-color: #ffffff15;
  transition: all 0.35s;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>input:checked+.tab-label {
  background: darken(#2c3e50, 10%);
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>input:checked+.tab-label::after {
  transform: rotate(90deg);
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>input:checked~.tab-content {
  max-height: 100vh;
  padding: 1em;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-content>ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-content>ul>li {
  width: inherit;
  text-align: center;
  list-style: none;
  cursor: pointer;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-content>ul>li>a {
  color: #202020;
  border-bottom: solid 1px #f9b11c;
  font-weight: 500;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  border-radius: 10px;
  transition: background 0.3s;
}

.BcxSectionF>.conteudo>.texto>.inscMunic>.tabs>.tab>.tab-content>ul>li:hover a {
  color: #ffffff;
  background-color: #f9b11c;
  border-color: #f9b11c;
}

/* BCXSECTIONG */

#sectionG {
  background: url(../imgs/bg-noise.png) repeat #324D54 fixed;
}

#sectionG>.container {
  display: flex;
  justify-content: center;
}

.BcxSectionG {
  color: #ffffff;
  padding: 100px 20px;
}

.BcxSectionG>.title {
  margin-bottom: 50px;
}

.BcxSectionG>.title,
.BcxSectionG>.title>span {
  text-align: center;
  font-size: 43px;
  font-weight: 400;
}

.BcxSectionG>.title>span {
  font-weight: 600;
}

.BcxSectionG>.duvida {
  border: 2px #fff solid;
  border-radius: 100px;
  padding: 30px 50px;
  margin-bottom: 15px;
  text-align: center;
}

.BcxSectionG>.duvida>h3 {
  font-size: 25px;
  margin-bottom: 25px;
}

.BcxSectionG>h4 {
  margin-top: 50px;
  font-size: 22px;
  text-align: center;
}

/* BCXFOOTER */

.CcxFooter>.logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
}

.CcxFooter>.logos img{
  display: block;
  height: auto;
  max-width: 100%;
}

.CcxFooter>.logos>a>img {
  height: 125px;
}