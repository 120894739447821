#sectionForm {
  background: url(../imgs/bg-noise.png) repeat #004387;
  /* background-size: cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
}

@media (max-width: 574px) {
  #sectionForm .formHeader {
    display: flex;
    flex-direction: column;
    padding: 0 0 80px 0;
    align-items: center;
    width: 100%;
  }

  #sectionForm .formHeader h4 {
    text-align: center;
    padding: 25px 0 0 0;
  }
}

@media (min-width: 576px) {
  #sectionForm .formHeader {
    display: flex;
    flex-direction: column;
    padding: 0 0 80px 0;
    align-items: center;
    width: 100%;
  }

  #sectionForm .formHeader h4 {
    text-align: center;
    padding: 25px 0 0 0;
  }
}

@media (min-width: 768px) {
  #sectionForm .formHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1320px;
    width: 100%;
  }

  #sectionForm .formHeader h4 {
    text-align: right;
  }
}

#sectionForm .formHeader .fHeaderTitle,
#sectionForm .formHeader h4 {
  font-weight: 300;
  color: #fff;
}

#sectionForm .formHeader .fHeaderTitle,
#sectionForm .formHeader .fHeaderTitle strong {
  font-size: 43px;
}

@media (max-width: 574px) {
  #sectionForm form {
    width: 350px;
  }
}

@media (min-width: 576px) {
  #sectionForm form {
    width: 540px;
  }
}

@media (min-width: 768px) {
  #sectionForm form {
    width: 720px;
  }
}

@media (min-width: 992px) {
  #sectionForm form {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  #sectionForm form {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  #sectionForm form {
    width: 1320px;
  }
}

#sectionForm form .btnSend {
  text-align: center;
  padding: 50px 0 0 0;
}

#sectionForm form .btnSend :hover {
  background-color: #ffffff !important;
  color: #47bb00;
}

#sectionForm form .btnSend Button {
  cursor: pointer;
  width: 75%;
  padding: 15px 0;
  border-radius: 50px;
  font-weight: 600;
  font-size: 20px;
  background-color: #47bb00;
}

#sectionForm .fileUpload {
  margin-top: 50px;
}

#sectionForm .fileUpload h4 {
  padding: 10px 0 10px 30px;
  color: #fff;
}

#sectionForm .fileUpload p {
  padding: 10px 0 10px 30px;
  color: rgb(162, 162, 162);
}

#sectionForm .fileUpload .erroForm {
  margin-left: 30px;
}

#sectionForm .fileUpload .uploadAnexo {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

#sectionForm .fileUpload .uploadAnexo ::-webkit-file-upload-button {
  background: #3b9901;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}

#sectionForm .fileUpload .uploadAnexo ::file-selector-button {
  background: #3b9901;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}

#sectionForm .fileUpload .uploadAnexo ::-webkit-file-upload-button:hover {
  background: #0090d6;
  color: #004387;
}

#sectionForm .fileUpload .uploadAnexo ::file-selector-button:hover {
  background: #0090d6;
  color: #004387;
}

#sectionForm .fileUpload .uploadAnexo .campoStyle {
  width: 100%;
  padding: 15px;
  color: #47bb00;
  font-size: 16px;
  font-weight: 700;
}

#sectionForm .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 20px;
}

#sectionForm .grid .personalColors {
  color: #fff;
}

#sectionForm .grid .campoStyle {
  background-color: rgb(255, 255, 255);
  max-height: 55px;
  border-radius: 50px;
  width: 100%;
}

#sectionForm .grid .campoStyle ::before,
#sectionForm .grid .campoStyle ::after {
  border: 0px;
}

#sectionForm .grid .campoStyle label {
  font-family: "Montserrat", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 500;
}

#sectionForm .grid .radioStyle {
  background-color: rgba(255, 255, 255, 0.1);
  /* Estilo antigo padrão 
  border-radius: 50px;
  max-height: 80px; */
  border-radius: 85.44px;
  max-height: max-content !important;
}

#sectionForm .grid .radioStyle span:nth-child(1) {
  color: #47bb00;
}

#sectionForm .grid .radioStyle label {
  color: #fff;
}

@media (max-width: 574px) {
  #sectionForm .grid {
    width: inherit;
  }

  #sectionForm .grid .Empresa,
  #sectionForm .grid .CNPJ,
  #sectionForm .grid .Telefone,
  #sectionForm .grid .CEP,
  #sectionForm .grid .Endereco,
  #sectionForm .grid .Bairro,
  #sectionForm .grid .E-mail,
  #sectionForm .grid .Faturamento,
  #sectionForm .grid .Porte,
  #sectionForm .grid .Atividade,
  #sectionForm .grid .Associado,
  #sectionForm .grid .Associado2,
  #sectionForm .grid .Agencia,
  #sectionForm .grid .Agencia2 {
    grid-column: 1/13;
  }

  #sectionForm .grid .Estado {
    grid-column: 1/7;
  }

  #sectionForm .grid .Cidade {
    grid-column: 7/13;
  }

  #sectionForm .grid .Porte {
    align-items: center;
  }

  #sectionForm .grid .Atividade .radioAtividade,
  #sectionForm .grid .Associado .radioAtividade,
  #sectionForm .grid .Associado2 .radioAtividade {
    display: flex;
    justify-content: center;
  }

  #sectionForm .grid .Atividade,
  #sectionForm .grid .Associado,
  #sectionForm .grid .Associado2 {
    max-height: 125px;
    align-items: center;

  }

  .customFormControlLabel {
    margin-right: 20px;
    /* Ajuste o espaçamento à direita conforme necessário */
  }

  .css-1nrlq1o-MuiFormControl-root {
    border: 2px solid red !important;
  }

  #sectionForm .grid .Agencia {
    text-align: center;
  }

  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .nomeSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .emailSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/7;
  }

  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 7/13;
  }

  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .rgSocio1 {
    grid-column: 1/7;
  }

  #sectionForm .grid .sexoSocio1 {
    grid-column: 7/13;
  }

  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .cepSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .enderecoSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .estadoSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .cidadeSocio1 {
    grid-column: 5/13;
  }

  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 3/11;
  }

  #sectionForm .grid .cidadeSocio2 {
    grid-column: 5/13;
  }

  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 3/11;
  }

  #sectionForm .grid .complementoSocio1 {
    grid-column: 1/13;
  }

  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }

  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }

  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }

  #sectionForm .grid .profissaoCj {
    grid-column: 1/13;
  }

  #sectionForm .grid .rendaCj {
    grid-column: 1/13;
  }

  #sectionForm .grid .valorCredito {
    grid-column: 1/13;
  }

  #sectionForm .grid .nmParcelas {
    grid-column: 1/7;
  }

  #sectionForm .grid .carencia {
    grid-column: 7/13;
  }

  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 400px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  #sectionForm .grid {
    width: inherit;
  }

  #sectionForm .grid .Empresa {
    grid-column: 1/8;
  }

  #sectionForm .grid .CNPJ,
  #sectionForm .grid .Endereco {
    grid-column: 8/13;
  }

  #sectionForm .grid .Telefone,
  #sectionForm .grid .Bairro {
    grid-column: 1/5;
  }

  #sectionForm .grid .CEP {
    grid-column: 5/8;
  }

  #sectionForm .grid .Estado {
    grid-column: 5/9;
  }

  #sectionForm .grid .Cidade {
    grid-column: 9/13;
  }

  #sectionForm .grid .E-mail {
    grid-column: 1/6;
  }

  #sectionForm .grid .Faturamento {
    grid-column: 6/13;
  }

  #sectionForm .grid .Porte,
  #sectionForm .grid .Atividade,
  #sectionForm .grid .Associado {
    grid-column: 1/13;
    align-items: center;
  }

  #sectionForm .grid .Agencia {
    grid-column: 1/13;
    text-align: center;
  }

  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .nomeSocio1 {
    grid-column: 5/13;
  }

  #sectionForm .grid .emailSocio1 {
    grid-column: 1/9;
  }

  #sectionForm .grid .telefoneSocio1 {
    grid-column: 9/13;
  }

  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 5/13;
  }

  #sectionForm .grid .rgSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .sexoSocio1 {
    grid-column: 5/8;
  }

  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 8/13;
  }

  #sectionForm .grid .cepSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .enderecoSocio1 {
    grid-column: 5/13;
  }

  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .estadoSocio1 {
    grid-column: 5/8;
  }

  #sectionForm .grid .cidadeSocio1 {
    grid-column: 8/13;
  }

  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 4/10;
  }

  #sectionForm .grid .cidadeSocio2 {
    grid-column: 8/13;
  }

  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 8/13;
  }

  #sectionForm .grid .complementoSocio1 {
    grid-column: 1/8;
  }

  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }

  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }

  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }

  #sectionForm .grid .profissaoCj {
    grid-column: 8/13;
  }

  #sectionForm .grid .rendaCj {
    grid-column: 4/10;
  }

  #sectionForm .grid .valorCredito {
    grid-column: 1/13;
  }

  #sectionForm .grid .nmParcelas {
    grid-column: 1/7;
  }

  #sectionForm .grid .carencia {
    grid-column: 7/13;
  }

  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  #sectionForm .grid {
    width: inherit;
  }

  #sectionForm .grid .Empresa {
    grid-column: 1/7;
  }

  #sectionForm .grid .CNPJ {
    grid-column: 7/10;
  }

  #sectionForm .grid .Telefone,
  #sectionForm .grid .Bairro {
    grid-column: 10/13;
  }

  #sectionForm .grid .CEP,
  #sectionForm .grid .Estado {
    grid-column: 1/5;
  }

  #sectionForm .grid .Endereco {
    grid-column: 5/10;
  }

  #sectionForm .grid .Cidade {
    grid-column: 5/8;
  }

  #sectionForm .grid .E-mail {
    grid-column: 8/13;
  }

  #sectionForm .grid .Faturamento {
    grid-column: 1/7;
  }

  #sectionForm .grid .Porte,
  #sectionForm .grid .Associado {
    grid-column: 7/13;
    align-items: center;
  }

  #sectionForm .grid .Atividade {
    grid-column: 1/7;
    align-items: center;
  }

  #sectionForm .grid .Associado2 {
    grid-column: 1/13;
  }

  #sectionForm .grid .Atividade .radioAtividade,
  #sectionForm .grid .Associado .radioAtividade,
  #sectionForm .grid .Associado2 .radioAtividade {
    display: flex;
    justify-content: center;
  }

  #sectionForm .grid .Atividade,
  #sectionForm .grid .Associado,
  #sectionForm .grid .Associado2 {
    max-height: 125px;
    align-items: center;
  }

  #sectionForm .grid .Agencia,
  #sectionForm .grid .Agencia2 {
    grid-column: 3/11;
    text-align: center;
  }

  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/4;
  }

  #sectionForm .grid .nomeSocio1 {
    grid-column: 4/9;
  }

  #sectionForm .grid .emailSocio1 {
    grid-column: 9/13;
  }

  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/4;
  }

  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 4/7;
  }

  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 7/13;
  }

  #sectionForm .grid .rgSocio1 {
    grid-column: 1/4;
  }

  #sectionForm .grid .sexoSocio1 {
    grid-column: 4/6;
  }

  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 6/9;
  }

  #sectionForm .grid .cepSocio1 {
    grid-column: 9/13;
  }

  #sectionForm .grid .enderecoSocio1 {
    grid-column: 1/7;
  }

  #sectionForm .grid .bairroSocio1 {
    grid-column: 7/11;
  }

  #sectionForm .grid .estadoSocio1 {
    grid-column: 11/13;
  }

  #sectionForm .grid .cidadeSocio1 {
    grid-column: 1/10;
  }

  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 10/13;
  }

  #sectionForm .grid .cidadeSocio2 {
    grid-column: 1/5;
  }

  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 10/13;
  }

  #sectionForm .grid .complementoSocio1 {
    grid-column: 5/10;
  }

  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  #sectionForm .grid .estadoCivilSocio1 .estadoCivilRadio {
    display: flex;
    justify-content: center;
  }

  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }

  #sectionForm .grid .profissaoCj {
    grid-column: 10/13;
  }

  #sectionForm .grid .rendaCj {
    grid-column: 4/10;
  }

  #sectionForm .grid .valorCredito {
    grid-column: 1/6;
  }

  #sectionForm .grid .nmParcelas {
    grid-column: 6/9;
  }

  #sectionForm .grid .carencia {
    grid-column: 9/13;
  }

  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }

  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/13;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 1/13;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    width: 100%;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    width: 100%;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados {
    grid-column: 1/7;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    grid-column: 7/13;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  #sectionForm .grid {
    width: inherit;
  }

  #sectionForm .grid .Empresa {
    grid-column: 1/7;
  }

  #sectionForm .grid .CNPJ,
  #sectionForm .grid .Bairro {
    grid-column: 7/10;
  }

  #sectionForm .grid .CEP,
  #sectionForm .grid .Cidade {
    grid-column: 1/3;
  }

  #sectionForm .grid .Endereco {
    grid-column: 3/7;
  }

  #sectionForm .grid .Estado,
  #sectionForm .grid .Telefone {
    grid-column: 10/13;
  }

  #sectionForm .grid .E-mail {
    grid-column: 3/6;
  }

  #sectionForm .grid .Faturamento {
    grid-column: 6/10;
  }

  #sectionForm .grid .Porte {
    grid-column: 10/13;
    padding: 0 0 0 5px;
  }

  #sectionForm .grid .Atividade {
    grid-column: 1/6;
    align-self: center;
    padding: 5px 0;
  }

  #sectionForm .grid .Associado {
    grid-column: 6/10;
    align-self: center;
    padding: 5px 0;
  }

  #sectionForm .grid .Associado2 {
    grid-column: 10/13;
    align-self: center;
    align-items: center;
    padding: 5px 0;
  }

  #sectionForm .grid .Agencia {
    grid-column: 10/13;
  }

  #sectionForm .grid .Agencia2 {
    grid-column: 10/13;
    text-align: center;
  }

  #sectionForm .grid .cpfSocio1 {
    grid-column: 1/4;
  }

  #sectionForm .grid .nomeSocio1 {
    grid-column: 4/10;
  }

  #sectionForm .grid .emailSocio1 {
    grid-column: 10/13;
  }

  #sectionForm .grid .telefoneSocio1 {
    grid-column: 1/4;
  }

  #sectionForm .grid .nascimentoSocio1 {
    grid-column: 4/7;
  }

  #sectionForm .grid .nomemaeSocio1 {
    grid-column: 7/13;
  }

  #sectionForm .grid .rgSocio1 {
    grid-column: 1/3;
  }

  #sectionForm .grid .sexoSocio1 {
    grid-column: 3/4;
  }

  #sectionForm .grid .naturalidadeSocio1 {
    grid-column: 4/6;
  }

  #sectionForm .grid .cepSocio1 {
    grid-column: 6/8;
  }

  #sectionForm .grid .enderecoSocio1 {
    grid-column: 8/13;
  }

  #sectionForm .grid .bairroSocio1 {
    grid-column: 1/5;
  }

  #sectionForm .grid .estadoSocio1 {
    grid-column: 5/7;
  }

  #sectionForm .grid .cidadeSocio1 {
    grid-column: 7/10;
  }

  #sectionForm .grid .sociedadeSocio1 {
    grid-column: 10/13;
  }

  #sectionForm .grid .cidadeSocio2 {
    grid-column: 7/10;
  }

  #sectionForm .grid .sociedadeSocio2 {
    grid-column: 5/10;
  }

  #sectionForm .grid .complementoSocio1 {
    grid-column: 10/13;
  }

  #sectionForm .grid .estadoCivilSocio1 {
    grid-column: 1/13;
    align-items: center;
  }

  #sectionForm .grid .conjugeFields {
    grid-column: 1/13;
  }

  #sectionForm .grid .profissaoCj {
    grid-column: 1/7;
  }

  #sectionForm .grid .rendaCj {
    grid-column: 7/13;
  }

  #sectionForm .grid .valorCredito {
    grid-column: 1/5;
  }

  #sectionForm .grid .nmParcelas {
    grid-column: 5/8;
  }

  #sectionForm .grid .carencia {
    grid-column: 8/13;
  }

  #sectionForm .grid .creditoEmprego {
    display: grid;
    grid-column: 1/13;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }

  #sectionForm .grid .creditoEmprego .fCredito {
    grid-column: 1/7;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito {
    width: 100%;
    padding: 20px 0 0 20px;
    min-height: 330px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito label {
    margin-bottom: 20px;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio {
    font-weight: bold;
  }

  #sectionForm .grid .creditoEmprego .fCredito .finalidadeCredito .finalidadeCreditoRadio label {
    margin: 0;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos {
    grid-column: 7/13;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 20px;
    align-items: center;
    width: 100%;
  }

  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregados,
  #sectionForm .grid .creditoEmprego .fEmpregos .quantosEmpregos {
    grid-column: 3/13;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  #sectionForm .grid {
    width: inherit;
  }
}

@media (min-width: 1400px) {
  #sectionForm .grid {
    width: inherit;
  }
}

#sectionForm .socioTitle {
  border-bottom: 2px solid #fff;
  color: #ffffff;
  margin: 50px 0;
  padding: 10px 0;
  width: inherit;
}

#sectionForm .conjugeTitle {
  color: #ffffff;
  margin: 50px 0;
  padding: 10px 0;
  width: inherit;
}

#sectionForm .conjugeTitle h2 {
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

#sectionForm .addSocio {
  width: 100%;
  margin: 50px 0;
  padding: 15px 0;
  background-color: #47bb00;
  font-size: 18px;
  color: #ffffff;
  border-radius: 25px;
}

#sectionForm .addSocio:hover {
  background-color: #3b9901;
  color: #fff;
}

#sectionForm .exclusaoSocio {
  width: 100%;
  display: flex;
  justify-content: right;
}

#sectionForm .exclusaoSocio .excluiSocio {
  margin: 0 0 25px 0;
  background-color: #c62828;
  padding: 15px 10px;
}

#sectionForm .exclusaoSocio .excluiSocio:hover {
  background-color: #d50000;
}

.termosAceite {
  margin: 50px 0;
}

.termosAceite .termosGroup {
  display: flex !important;
  flex-direction: column !important;
  gap: 25px !important;
  color: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.termosAceite .termosGroup .politicaCheck {
  display: flex;
  align-items: center;
}

.termosAceite .termosGroup .politicaCheck .checkbox svg {
  margin-right: 15px;
}

.termosAceite .termosGroup .politicaCheck p a {
  color: #47bb00;
  font-weight: 500;
}

.termosAceite .checkbox {
  padding: 25px 0 25px 15px;
}

.termosAceite .checkbox svg {
  color: #fff;
  margin-right: 25px;
}

.termosAceite .popupctd {
  position: relative;
  color: #1a1a1a;
  max-width: 100%;
}

.termosAceite .popupctd h3 {
  padding-bottom: 15px;
}

.termosAceite .popupctd p {
  margin-bottom: 15px;
}

.termosAceite .docSolicita,
.termosAceite .docAnalise {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
}

.termosAceite .docSolicita {
  padding: 50px 0 50px 0;
  font-style: italic;
  font-weight: 400;
  color: #ffffff;
}

.termosAceite .docAnalise {
  font-weight: 600;
  font-style: italic;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  z-index: 10000;
  overflow-y: scroll;
}

.popup-inner {
  position: relative;
  padding: 32px;
  height: -moz-max-content !important;
  height: max-content !important;
  max-width: 1000px;
  background-color: #fff;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}